
































































































































































import {Component, Vue} from 'vue-property-decorator';
import EditIcon from '@/assets/icons/edit-pen.svg';
import LanguageDropdown from '@/components/LanguageDropdown.vue';
import PolicyIcon from '@/assets/icons/file-text.svg';
import ProfilePasswordBlock from '@/pages/Profile/Profile.password.block.vue';
import LogOut from '@/components/Modals/LogOut.modal.vue';
import {i18n} from '@/main';
import ProfileCardChangeBlock from './Profile.cardChange.block.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import ServerValidation from '@/mixins/serverValidation.mixin';
import { mixins } from 'vue-class-component';
import {Language, IUserInfo} from '@/interfaces';
import {cloneObject, EnvProvider} from '@/utilities';
import {getSiteUrl} from '@/utilities/getUrl';
import {scrollTo} from '@/utilities/scroll';

const disabledItems = () => ({
  email: true,
  phoneCode: true,
  phoneNumber: true
});

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.profile.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.profile.description')
      }]
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    LanguageDropdown,
    ProfilePasswordBlock,
    LogOut,
    ProfileCardChangeBlock,
    EditIcon,
    PolicyIcon,
  }
})
export default class ProfilePage extends mixins(ServerValidation) {
  public $refs!: { observer: Vue };
  public user: IUserInfo = cloneObject(this.$store.getters.getUserInfo);

  public showPasswordChange = false;
  public logOutModal = false;

  private disabledItems = disabledItems();

  public mounted() {
    if (this.$router.currentRoute.query['new-payment'] === 'true') {
      this.openStripeEdit();
    }
  }
  private changeDisable(field) {
    this.disabledItems[field] = !this.disabledItems[field];
  }

  private logOut() {
    this.$store.dispatch('logOut');
  }

  private onSubmit() {
    this.$store.dispatch('updateProfile', this.user)
      .then(() => {
        this.$notify({
          group: 'notification',
          type: 'notification-success',
          text: this.$tc('profile.updated')
        });
        this.disabledItems = disabledItems();
        if (this.user.language !== this.$store.getters.language) {
          this.$router.push({params: {lang: this.user.language}});
        }
      })
      .catch((error) => this.setServerValidationErrors(
        this.$refs.observer,
        this.getServerErrors(error)
      ));
  }

  private openStripeEdit() {
    if (!this.$store.getters.getStripeKey) {
      this.$store.dispatch('getStripeKey')
        .then(() => scrollTo('#paymentForm'));
    }
  }

  private get getUrlDataPrivacyDocument() {
    return this.$store.getters.language === Language.EN
      ? getSiteUrl(EnvProvider('URL_WEBSITE_PRIVACY_EN'))
      : getSiteUrl(EnvProvider('URL_WEBSITE_PRIVACY_DE'));
  }

  private get getUrlTermsConditionDocument() {
    return this.$store.getters.language === Language.EN
      ? getSiteUrl(EnvProvider('URL_WEBSITE_TERMS_EN'))
      : getSiteUrl(EnvProvider('URL_WEBSITE_TERMS_DE'));
  }

  private get isSaveDisabled() {
    return JSON.stringify(this.user) === JSON.stringify(this.$store.getters.getUserInfo);
  }

  private updatePayment(id: string) {
    this.$store.dispatch('getProfilePayment', id)
      .then((user) => this.user = cloneObject(user));
  }
}
