





















































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';

@Component({
  components: { ValidationObserver, ValidationProvider },
})
export default class ProfilePasswordBlock extends Vue {
  private password = '';
  private reEnteredPassword = '';

  private onSubmit() {
    this.$store.dispatch('setNewPassword', {
      password: this.password,
      reEnteredPassword: this.reEnteredPassword,
    }).then(() => this.$emit('close', false));
  }
}
