import { render, staticRenderFns } from "./LanguageDropdown.vue?vue&type=template&id=4551888e&scoped=true&lang=pug&"
import script from "./LanguageDropdown.vue?vue&type=script&lang=ts&"
export * from "./LanguageDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./LanguageDropdown.vue?vue&type=style&index=0&id=4551888e&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4551888e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
