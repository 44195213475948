var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"password-change",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-block"},[_c('div',{staticClass:"form-block__title"},[_vm._v(_vm._s(_vm.$t('profile.passwordChange.title')))]),_c('div',{staticClass:"form-block__section section"},[_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var touched = ref.touched;
var changed = ref.changed;
return [_c('Input',{staticClass:"item",attrs:{"type":"password","error-messages":(invalid && touched || invalid && !changed) ? errors[0] : null,"label":_vm.$t('profile.passwordChange.newPass'),"maxlength":"128","data-test-id":"new-password-input"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmedPassword:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Input',{staticClass:"item",attrs:{"type":"password","error-messages":errors[0],"label":_vm.$t('profile.passwordChange.retypePass'),"maxlength":"128","data-test-id":"retype-new-password-input"},model:{value:(_vm.reEnteredPassword),callback:function ($$v) {_vm.reEnteredPassword=$$v},expression:"reEnteredPassword"}})]}}],null,true)})],1)]),_c('v-container',{staticClass:"help"},[_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.$t('profile.passwordChange.help.title'))),_c('ul',{staticClass:"password-change-rules-list"},[_c('li',[_vm._v(_vm._s(_vm.$t('profile.passwordChange.help.rule1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('profile.passwordChange.help.rule2')))]),_c('li',[_vm._v(_vm._s(_vm.$t('profile.passwordChange.help.rule3')))]),_c('li',[_vm._v(_vm._s(_vm.$t('profile.passwordChange.help.rule4')))])])])])],1),_c('div',{staticClass:"form-buttons"},[_c('Button',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close', false)}}},[_vm._v(_vm._s(_vm.$t('profile.passwordChange.cancel')))]),_c('Button',{attrs:{"type":"submit","color":"primary"}},[_vm._v(_vm._s(_vm.$t('profile.passwordChange.saveChange')))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }