



























import {Component, Prop, Vue} from 'vue-property-decorator';
import {getIconByLanguage} from '@/dictionaries/icons.language.dictionary';
import {ILanguage, LanguageCodeType, LanguageNameEnum} from '@/interfaces';

@Component({
  inheritAttrs: false,
})
export default class LanguageDropdown extends Vue {
  @Prop({type: String, required: true})
  private value!: LanguageCodeType;

  private getIconByLanguage = getIconByLanguage;
  private get getAvailableLanguages(): ILanguage[] {
    return this.$store.getters.getCurrentSettings.languages.map((language) =>
      ({name: LanguageNameEnum[language], code: language}));
  }

  public get attrs() {
    return {
      ...this.$attrs,
      class: this.getClass,
      value: this.value,
      items: this.getAvailableLanguages,
      'item-text': 'name',
      'item-value': 'code',
      'hide-details': true,
    };
  }

  public get listeners() {
    return {
      ...this.$listeners,
      input: (language) => this.$emit('input', language)
    };
  }

  private get getClass() {
    return {
      'small': this.$attrs.small
    };
  }
}
