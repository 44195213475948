












import { Component, Vue } from 'vue-property-decorator';
import {loadStripe} from '@stripe/stripe-js/pure';
import {CheckoutLocale, Stripe, StripeElements} from '@stripe/stripe-js';
import {createHelpers} from 'vuex-map-fields';
import {IStripeInfo} from '@/store/modules/user.store';
import {EnvProvider} from '@/utilities';
import { LocaleService } from '@/utilities/locale';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  computed: {...mapFields(['stripeInfo'])}
})
export default class ProfileCardChangeBlock extends Vue {
  private stripeInfo!: null | IStripeInfo;
  private stripe: Stripe | null = null;
  private stripeElements: StripeElements | null = null;
  private countryCode = LocaleService.getCountryFromDomain();
  private stripeKey = this.countryCode === 'at' ? EnvProvider('STRIPE_SECRET_AT') : EnvProvider('STRIPE_SECRET_DE');

  public created() {
    this.mountStripe();
  }

  private mountStripe() {
    loadStripe(this.stripeKey, {locale: this.$store.getters.language as CheckoutLocale})
      .then((answer) => {
        this.stripe = answer;
        const options = {
          clientSecret: this.$store.getters.getStripeKey,
        };
        this.stripeElements = this.stripe!.elements(options);

        const addressElement = this.stripeElements.create('address', {
          mode: 'billing',
          autocomplete: {
            mode: 'disabled'
          },
          defaultValues: {
            name: `${this.userInfo.name}`,
            address: {
              country: `${this.userInfo.addressInfo.country}`,
              postal_code: `${this.userInfo.addressInfo.zip}`,
              state: '',
              city: `${this.userInfo.addressInfo.city}`,
              line1: `${this.userInfo.addressInfo.street}`,
              line2: `${this.userInfo.addressInfo.streetNumber}`,
            }
          },
        });

        const paymentElement = this.stripeElements.create('payment',
          {
            terms: {
              card: 'never',
              sepaDebit: 'never'
            },
            defaultValues: {
              billingDetails: {
                email: `${this.userInfo.email}`
              }
            }
          });

        addressElement.mount('#address-element');
        paymentElement.mount('#payment-element');
    });
  }

  private get userInfo() {
    return this.$store.getters.getUserInfo;
  }

  private async saveCardInfo() {
    this.$store.commit('SET_LOADING', {payments: true});
    const {error} = await this.stripe!.confirmSetup({
      elements: this.stripeElements as StripeElements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: this.userInfo.name ,
            email: this.userInfo.email,
            // @ts-ignore
            address: {
              line1: this.userInfo.addressInfo.street,
              line2: this.userInfo.addressInfo.streetNumber,
              city: this.userInfo.addressInfo.city,
              state: '',
              postal_code: this.userInfo.addressInfo.zip,
              country: this.userInfo.addressInfo.country},
          }
        },
      },
      redirect: 'if_required'
    });

    if (error) {
      this.$store.commit('SET_LOADING', {payments: false});
      this.$notify({
        group: 'notification',
        type: 'notification-error',
        text: error.message
      });
    } else {
      this.$store.commit('SET_LOADING', {payments: false});
      this.$notify({
        group: 'notification',
        type: 'notification-success',
        text: this.$tc('profile.cardChange.success')
      });
      this.$emit('paymentUpdated', this.stripeInfo?.id);
      this.stripeInfo = null;
    }
  }
}
